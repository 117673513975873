
import { IonHeader, IonTitle, IonToolbar, IonButtons, IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, albumsSharp, albumsOutline, bookOutline, bookSharp, mapSharp, mapOutline,logOutOutline, logOut } from 'ionicons/icons';
import { authService } from './services/AuthService';

export default defineComponent({
  name: 'App',
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonMenu, 
    IonMenuButton,
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Overview',
        url: '/overview',
        iosIcon: mapOutline,
        mdIcon: mapSharp
      },
      {
        title: 'Logout',
        url: '/logout',
        iosIcon: logOutOutline,
        mdIcon: logOut
      }
    ];
    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();
    
    return { 
      selectedIndex,
      appPages, 
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  },
  computed: {
    currentRouteName() {
        return this.$route.path;
    }
  },
  methods: {
    clickedMenuItem(item: any) {
    console.log(item)
    if(item.title === 'Logout')
    {
      // remove user from local storage to log user out
      authService.logout()
      this.$router.push({ path: '/login' })
      location.reload()
    } else{
      setTimeout(function() {
                    location.reload()
                  }, 200);
    }
    }
  },
  mounted() {
    if (localStorage.getItem('user')) {
      this.userDetails = JSON.parse(localStorage.getItem('user'));
      this.currentUserEmail = JSON.parse(localStorage.getItem('user')).email;
      this.currentUserIsAdmin = this.userDetails.userAppConfig.find((x: any) => x.key === 'user_is_admin').value
      if(this.currentUserIsAdmin && this.currentUserIsAdmin === 'true'){
      this.appPages = [
      {
        title: 'Overview',
        url: '/overview',
        iosIcon: mapOutline,
        mdIcon: mapSharp
      },
      {
        title: 'Admin Devices',
        url: '/admindevices',
        iosIcon: albumsOutline,
        mdIcon: albumsSharp
      },
      {
        title: 'Reports',
        url: '/reports',
        iosIcon: bookOutline,
        mdIcon: bookSharp
      },
      {
        title: 'Logout',
        url: '/logout',
        iosIcon: logOutOutline,
        mdIcon: logOut
      }
    ];
      }
    }

    // If url contains dev or localhost point API calls to dev
    if(window.location.href.includes('dev.') || window.location.href.includes('localhost')){
      this.isSiteDev = true
    } else { 
      this.isSiteDev = false
    }
  },
  data() {
    return {
      currentUserEmail: null,
      userDetails: null,
      currentUserIsAdmin: null,
      isSiteDev: false
    };
  }
});
