<template>
<ion-content >
Sorry Page Not Found!
</ion-content>
</template>

<script>
import { IonContent } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { IonContent },
  setup() {
    return {  };
  },
  data() {
    return {
      
    };
  },
  methods: {
  }
});
</script>

<style scoped>

</style>